import React from 'react'
import GoogleButton from '../../../components/UI/Buttons/GoogleButton/GoogleButton'
import { API_URL, GOOGLE_CLIENT_ID } from '../../../config/consts'
import { message } from 'antd'
import { IAccount } from '../../../types/account.type'

interface IProps {
  account: IAccount
}

const GoogleConnectionButton = ({ account }: IProps) => {

  const handleGoogleOAuth = () => {
    const accountId = account.id

    const clientId = GOOGLE_CLIENT_ID
    const redirectUri = encodeURIComponent(`${API_URL}/auth/oauth2`)

    if (!clientId || !redirectUri) {
      message.error('Google OAuth configuration is missing').then()
      return
    }

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/gmail.readonly',
      'https://www.googleapis.com/auth/gmail.compose',
      'https://www.googleapis.com/auth/gmail.modify',
      'https://www.googleapis.com/auth/gmail.send',
      'https://www.googleapis.com/auth/gmail.labels',
      'https://www.googleapis.com/auth/calendar',
      'https://www.googleapis.com/auth/calendar.events',
      'https://www.googleapis.com/auth/calendar.settings.readonly',
    ].join(' ') // Join scopes as a space-separated string

    // Construct the Google OAuth URL
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent&state=${accountId}`

    // Redirect the user to the Google Auth page
    window.location.href = authUrl
  }

  return (
    <GoogleButton handleGoogleOAuth={handleGoogleOAuth} />
  )
}

export default GoogleConnectionButton
